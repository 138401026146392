<template>
  <div class="space-y-6">
    <h2 class="text-xl font-semibold">{{ t('print.delivery-method') }}</h2>

    <div v-if="loading" class="bg-white rounded-lg p-4 lg:p-6 shadow-sm">
      <p class="text-gray">Loading shipping rates...</p>
    </div>

    <div v-else-if="error" class="bg-white rounded-lg p-4 lg:p-6 shadow-sm">
      <p class="text-red">{{ error }}</p>
    </div>

    <div v-else-if="shippingRates.length > 0" class="space-y-4">
      <div
        v-for="rate in shippingRates"
        :key="rate.serviceType"
        class="p-3 lg:p-4 border rounded-lg cursor-pointer"
        :class="
          selectedRate?.serviceType === rate.serviceType
            ? 'border-primary bg-primary/5'
            : 'border-gray-200'
        "
        @click="selectRate(rate)"
      >
        <div class="flex justify-between items-center">
          <div>
            <h3 class="font-medium">{{ rate.serviceName }}</h3>
            <p class="text-sm text-gray">{{ rate.transitDays }}</p>
          </div>
          <div class="text-base lg:text-lg font-medium">
            ${{ rate.price.toFixed(2) }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="bg-white rounded-lg p-4 lg:p-6 shadow-sm">
      <p class="text-gray">No shipping rates available</p>
    </div>

    <div class="flex justify-between pt-4">
      <button
        type="button"
        class="px-4 py-2 lg:px-6 text-gray hover:text-gray"
        @click="$emit('back')"
      >
        {{ t('print.back') }}
      </button>
      <button
        type="button"
        class="px-4 py-2 lg:px-6 bg-primary text-[white] rounded-lg hover:bg-primary-dark transition-colors"
        :disabled="loading || (!selectedRate && !error)"
        @click="handleContinue"
      >
        {{ t('print.continue-to-payment') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePrintStore } from '~/store/print';
import { apiGetShippingRates, type ShippingRate } from '~/api/print';

const { t } = useI18n();
const printStore = usePrintStore();
const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'back'): void;
}>();

const loading = ref(false);
const error = ref<string | null>(null);
const shippingRates = ref<ShippingRate[]>([]);
const selectedRate = ref<ShippingRate | null>(null);

const defaultShippingOption: ShippingRate = {
  serviceType: 'GROUND',
  serviceName: 'Standard Shipping',
  transitDays: '5-7 Business Days',
  price: 19,
};

const calculateTotalWeight = () => {
  let totalWeight = 0;
  for (const [key, item] of Object.entries(printStore.selectedItems)) {
    const quantity = printStore.quantities[key] || 0;
    if (!item.printProduct.weightOz) {
      continue;
    }
    const itemWeightLbs = item.printProduct.weightOz / 16;
    const itemTotalWeight = quantity * itemWeightLbs;

    totalWeight += itemTotalWeight;
  }

  const finalWeight = Math.max(totalWeight, 0.1);

  return finalWeight;
};

const prepareItemsForShipping = () => {
  const items = [];

  for (const [key, item] of Object.entries(printStore.selectedItems)) {
    const quantity = printStore.quantities[key] || 0;
    if (quantity > 0) {
      items.push({
        id: key,
        weight: item.printProduct.weightOz
          ? item.printProduct.weightOz / 16
          : 0.1, // Convert oz to lbs
        quantity,
      });
    }
  }

  return items;
};

onMounted(async () => {
  if (!printStore.checkout.shippingAddress?.address.postal_code) {
    error.value =
      'Missing postal code in shipping address. Using default shipping option.';
    shippingRates.value = [defaultShippingOption];
    selectedRate.value = defaultShippingOption;
    printStore.setShippingRate({
      service: defaultShippingOption.serviceName,
      amount: defaultShippingOption.price,
      estimatedDays: defaultShippingOption.transitDays,
    });
    return;
  }

  loading.value = true;
  error.value = '';

  try {
    if (Object.keys(printStore.selectedItems).length === 0) {
      error.value = 'No items selected. Using default shipping option.';

      shippingRates.value = [defaultShippingOption];
      selectedRate.value = defaultShippingOption;
      printStore.setShippingRate({
        service: defaultShippingOption.serviceName,
        amount: defaultShippingOption.price,
        estimatedDays: defaultShippingOption.transitDays,
      });
      loading.value = false;
      return;
    }

    const items = prepareItemsForShipping();

    const response = await apiGetShippingRates({
      toAddress: {
        postalCode: printStore.checkout.shippingAddress.address.postal_code,
        country: printStore.checkout.shippingAddress.address.country || 'US',
      },
      weight: calculateTotalWeight(),
      items,
    });

    shippingRates.value = response.rates;

    if (response.rates.length > 0) {
      const [firstRate] = response.rates;
      selectedRate.value = firstRate;
      printStore.setShippingRate({
        service: firstRate.serviceName,
        amount: firstRate.price,
        estimatedDays: firstRate.transitDays,
      });
    } else {
      shippingRates.value = [defaultShippingOption];
      selectedRate.value = defaultShippingOption;
      printStore.setShippingRate({
        service: defaultShippingOption.serviceName,
        amount: defaultShippingOption.price,
        estimatedDays: defaultShippingOption.transitDays,
      });
    }
  } catch {
    error.value =
      'Failed to fetch shipping rates. Using default shipping option.';

    shippingRates.value = [defaultShippingOption];
    selectedRate.value = defaultShippingOption;
    printStore.setShippingRate({
      service: defaultShippingOption.serviceName,
      amount: defaultShippingOption.price,
      estimatedDays: defaultShippingOption.transitDays,
    });
  } finally {
    loading.value = false;
  }
});

const selectRate = (rate: ShippingRate) => {
  selectedRate.value = rate;
  printStore.setShippingRate({
    service: rate.serviceName,
    amount: rate.price,
    estimatedDays: rate.transitDays,
  });
};

const handleContinue = () => {
  if (selectedRate.value) {
    emit('next');
  }
};
</script>
